.App {
  display: flex;
  flex-flow: column;
  height: 100vh;
  overflow: hidden;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: calc(12px + 0.5vw);
  font-weight: bold;
  background: #2b2b2b;
  color: white;
}

.about {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
  padding: 1rem;
  border: 4px solid white;
  font-size: 1.25em;
  line-height: 1.5em;
  font-weight: 200;
  background: black;
  color: white;
}

a {
  text-decoration: none;
  color: white;
}

p {
  max-width: 1080px;
}

.action {
  font-weight: 600;
  cursor: pointer;
}

.action:hover {
  text-shadow: -1px -1px 0 orange, 1px -1px 0 orange, -1px 1px 0 orange, 1px 1px 0 orange;
}