.container {
	/*box-sizing: border-box;*/
	position: fixed;

	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.detail {
	max-width: 1920px;
	display: flex;
	flex-flow: column;
	box-sizing: border-box;
	/*width: 62vw;*/
	/*height: 62vh;*/
	width: calc(100vw - 2em);
	height: calc(100vh - 2em);
	margin: auto;
	margin-top: 50vh;
	transform: translate(0, -50%);
	padding: 1rem;
	border: 4px solid white;
	font-weight: 200;
	background: black;

	/*	*/
	opacity: 0;
	transition: opacity 0.35s ease-in-out;
}

.row {
	display: grid;
	grid-template-columns: 5fr 1fr;
	border-bottom: 2px solid white;
	padding-bottom: 1em;
}

.title {
	font-weight: 600;
}

.close {
	text-align: right;
	cursor: pointer;
	/*vertical-align: middle;*/
	transform: translate(0, -15%);
	/*flex: 50% 0;*/
	/*display: block;*/
	/*width: ;*/
	/*display: inline-block;*/
}

.close:hover {
	text-shadow: -1px -1px 0 orange, 1px -1px 0 orange, -1px 1px 0 orange, 1px 1px 0 orange;
}

.content {
	font-size: 0.75em;
	overflow-x: hidden;
	overflow-y: scroll;
}

.content img {
	width: 100%;
}

.content a {
	font-weight: 600;
}
.content a:hover {
	text-shadow: -1px -1px 0 orange, 1px -1px 0 orange, -1px 1px 0 orange, 1px 1px 0 orange;
}
