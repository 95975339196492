.projects {
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	background-repeat: no-repeat;
	background-size: cover;
	transition: background 0.35s ease-in-out;
	overflow-x: hidden;
	overflow-y: scroll;
}

.project {
	display: inline-block;
	width: calc(33vw - 1em);
	padding: 1em;
	padding-right: 0;
	padding-bottom: 0;
	transition: border 0.35s ease-in-out;
}
.project:last-of-type {
	padding-bottom: 1em;
}

.cell {
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	min-height: 33vh;
	padding: 1rem;
	line-height: 1.5em;
	word-wrap: break-word;
	text-transform: uppercase;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	background-color: black;
	color: white;
	cursor: pointer;
	transition: all 0.35s ease-in-out;
}

@media screen and (max-width: 720px) {
	.project {
		width: calc(100vw - 2em);
	}
}